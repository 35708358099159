import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled from '@emotion/styled';

const ButtonStyled = styled.button`
    border: none;
    color: black;
    cursor: pointer;
    display: inline-flex;
    letter-spacing: 2px;
    padding: 10px 16px 8px 20px;
    text-decoration: none;
    text-transform: uppercase;
    background: linear-gradient(black 0 0) 0 100%, linear-gradient(black 0 0) 0 100%, linear-gradient(black 0 0) 100% 0,
        linear-gradient(black 0 0) 100% 0;
    background-size: 20px 3px, 3px 20px;
    background-repeat: no-repeat;
    transition: 0.5s;

    &:hover {
        background-position: 100% 100%, 0 0, 0 0, 100% 100%;
    }
`;

const Button = ({ children, href, onClick, target, to, ...props }) => {
    let as = 'button';
    if (href) {
        as = 'a';
    }
    if (to) {
        as = Link;
    }
    const rel = target === '_blank' ? 'noopener noreferrer' : '';

    const handleClick = useCallback(() => {
        if (onClick) {
            onClick();
        }
    }, [onClick]);

    return (
        <ButtonStyled as={as} href={href} onClick={handleClick} to={to} target={target} rel={rel} {...props}>
            {children}
        </ButtonStyled>
    );
};

Button.propTypes = {
    children: PropTypes.node,
    href: PropTypes.string,
    onClick: PropTypes.func,
    target: PropTypes.string,
    to: PropTypes.string,
};

export default Button;
