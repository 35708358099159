import React from 'react';
import styled from '@emotion/styled';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import config from '../../config/config';
import { MEDIA_QUERIES } from '../../utils/breakpoints';

const H1 = styled.h1`
    font-size: 56px;
    line-height: 88px;
    margin: 0;
    font-family: 'Melinda', 'Mrs Eaves', georgia, serif;
    font-weight: lighter;

    ${MEDIA_QUERIES.medium} {
        font-size: 80px;
        line-height: 120px;
    }
`;

const H2 = styled.h2`
    font-size: 24px;
    margin: 0;
    font-weight: lighter;

    ${MEDIA_QUERIES.medium} {
        font-size: 32px;
    }
`;

const MainTitle = () => {
    const { date, names } = config;
    const formattedDate = format(new Date(date), 'PPPP', { locale: fr });

    return (
        <div>
            <H1>
                {names[0]} <br /> {names[1]}
            </H1>
            <H2>{formattedDate}</H2>
        </div>
    );
};

export default MainTitle;
