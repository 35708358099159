import React from 'react';
import styled from '@emotion/styled';
import Event from './Event';
import config from '../../config/config';
import { MEDIA_QUERIES } from '../../utils/breakpoints';

const Section = styled.section`
    margin: 64px 0;

    ${MEDIA_QUERIES.medium} {
        margin: 128px 0;
    }
`;

const Events = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
`;

const EventSection = () => {
    const { events } = config;

    return (
        <Section>
            <Events>
                {events.map((event) => (
                    <Event event={event} key={event.title} />
                ))}
            </Events>
        </Section>
    );
};

export default EventSection;
