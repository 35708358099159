import React, { useEffect, useState } from 'react';
import { differenceInDays, differenceInHours, differenceInMinutes, differenceInSeconds, isBefore } from 'date-fns';
import styled from '@emotion/styled';
import config from '../../config/config';
import { MEDIA_QUERIES } from '../../utils/breakpoints';

const CountersWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
`;

const Counter = styled.div`
    margin: 0 8px;

    ${MEDIA_QUERIES.medium} {
        margin: 0 16px;
    }
`;

const Number = styled.div`
    font-size: 24px;

    ${MEDIA_QUERIES.medium} {
        font-size: 40px;
    }
`;

const Unit = styled.div`
    text-transform: uppercase;
    font-size: 14px;

    ${MEDIA_QUERIES.medium} {
        font-size: 16px;
    }
`;

const PastLabel = styled.div`
    text-align: center;
    font-size: 18px;
`;

const Countdown = () => {
    const date = new Date(config.date);
    const [currentDate, setCurrentDate] = useState(new Date());

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentDate(new Date());
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const days = differenceInDays(date, currentDate);
    const hours = differenceInHours(date, currentDate) - days * 24;
    const minutes = differenceInMinutes(date, currentDate) - days * 24 * 60 - hours * 60;
    const seconds = differenceInSeconds(date, currentDate) - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;
    const isWeddingPast = isBefore(date, currentDate);

    return (
        <div>
            {isWeddingPast && <PastLabel>Mariés depuis</PastLabel>}
            <CountersWrapper>
                <Counter>
                    <Number>{Math.abs(days)}</Number>
                    <Unit>Jours</Unit>
                </Counter>
                <Counter>
                    <Number>{Math.abs(hours)}</Number>
                    <Unit>Heures</Unit>
                </Counter>
                <Counter>
                    <Number>{Math.abs(minutes)}</Number>
                    <Unit>Minutes</Unit>
                </Counter>
                <Counter>
                    <Number>{Math.abs(seconds)}</Number>
                    <Unit>Secondes</Unit>
                </Counter>
            </CountersWrapper>
        </div>
    );
};

export default Countdown;
