import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Button from '../Button';

const EventWrapper = styled.div`
    width: 500px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 16px;
    margin: 16px 0;
`;

const Title = styled.h3`
    font-size: 24px;
    letter-spacing: 4px;
    margin-bottom: 0;
    text-transform: uppercase;
`;

const Text = styled.p`
    font-size: 18px;
`;

const Event = ({ event }) => {
    const { title, icon: Icon, locationLink, time, text } = event;

    return (
        <EventWrapper>
            <Icon size={128} />
            <Title>
                {title} - {time}
            </Title>
            <Text>
                {text.map((line) => (
                    <Fragment key={line}>
                        {line} <br />
                    </Fragment>
                ))}
            </Text>
            <Button href={locationLink} target="_blank">
                Y aller
            </Button>
        </EventWrapper>
    );
};

Event.propTypes = {
    event: PropTypes.object,
};

export default Event;
