import React from 'react';
import styled from '@emotion/styled';
import MainTitle from './MainTitle';
import Countdown from './Countdown';
import { MEDIA_QUERIES } from '../../utils/breakpoints';
import { HEADER_HEIGHT, MOBILE_HEADER_HEIGHT } from '../../constants';

const Section = styled.section`
    height: calc(100vh - ${MOBILE_HEADER_HEIGHT}px);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding-top: 0;

    ${MEDIA_QUERIES.tablet} {
        height: calc(100vh - ${HEADER_HEIGHT}px);
    }

    ${MEDIA_QUERIES.mediumHeight} {
        padding-top: 12vh;
    }

    ${MEDIA_QUERIES.largeHeight} {
        padding-top: 20vh;
    }
`;

const CountdownWrapper = styled.div`
    position: absolute;
    bottom: 32px;
`;

const MainSection = () => (
    <Section>
        <MainTitle />
        <CountdownWrapper>
            <Countdown />
        </CountdownWrapper>
    </Section>
);

export default MainSection;
